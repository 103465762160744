<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="90px">
            <el-form-item label="优惠卷名称" prop="spuName">
                <el-input v-model="query.couponName" placeholder="请输入优惠卷名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="优惠卷分类" prop="couponType">
                <el-select v-model="query.couponType" placeholder="请选择优惠卷类型" clearable size="small">
                    <el-option v-for="dict in couponDict" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="扣减类型" prop="reduceType">
                <el-select v-model="query.reduceType" placeholder="请选择扣减类型" clearable size="small">
                    <el-option v-for="dict in reduceDict" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="使用类型" prop="useType">
                <el-select v-model="query.useType" placeholder="请选择使用类型" clearable size="small">
                    <el-option v-for="dict in useDict" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="启用状态" prop="startStatus">
                <el-select v-model="query.startStatus" placeholder="请选择启用状态" clearable size="small">
                    <el-option v-for="dict in startDict" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-hasPermi="['sms:coupon:create']">新增</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
                    v-hasPermi="['sms:coupon:edit']">修改</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
                    v-hasPermi="['sms:coupon:remove']">删除</el-button>
            </el-col>

            <right-toolbar @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="赠票类型" align="center" prop="couponType">
                <template slot-scope="scope">
                    <el-tag :type="couponTypeDict[scope.row.couponType].color">{{
                        couponTypeDict[scope.row.couponType].label }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="优惠券名称" align="center" prop="couponName" width="90" />
            <el-table-column label="发行数量" align="center" prop="publishCount" />
            <el-table-column label="用户限领张数" align="center" prop="perLimit" width="110" />
            <el-table-column label="领取数量" align="center" prop="receiveCount" />
            <el-table-column label="减扣类型" align="center" prop="reduceType">
                <template slot-scope="scope">
                    <el-tag type="success">{{ scope.row.reduceType == '1' ? '金额' : '折扣' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="打折力度" align="center" prop="discount" >
                <template #default="scope">
                    {{ scope.row.reduceType == 1 ?0.00:scope.row.discount }}
                </template>
            </el-table-column>
            <el-table-column label="抵扣金额" align="center" prop="amount">
                <template slot-scope="scope">
                    <span style="color: red">￥{{ scope.row.reduceType == 1?scope.row.amount.toFixed(2):0.00 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="开始时间" align="center" prop="startTime" width="160" />
            <el-table-column label="结束时间" align="center" prop="endTime" width="160" />
            <el-table-column label="可以领取的开始日期" align="center" prop="enableStartTime" width="160" />
            <el-table-column label="可以领取的结束日期" align="center" prop="enableEndTime" width="160" />
            <el-table-column label="备注" align="center" prop="note" />
            <el-table-column label="启用状态" align="center" prop="startStatus">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.startStatus == '1' ? 'success' : 'danger'">
                        {{ scope.row.startStatus == '1' ? '启用' : '禁用' }}
                    </el-tag>
                </template>
            </el-table-column>


            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="100">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                        v-hasPermi="['sms:coupon:update']">修改
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" v-hasPermi="['sms:coupon:remove']">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />

        <!-- 添加或修改店铺对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="950px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="130px">
                <el-row>
                            <el-col :span="12">
                                <el-form-item label="优惠劵类型" prop="couponType">
                                    <el-select v-model="form.couponType" filterable placeholder="请选择">
                                        <el-option v-for="item in couponDict" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="14">
                                <el-form-item label="优惠劵名称" prop="couponName">
                                    <el-input v-model="form.couponName" placeholder="请输入优惠劵名称" />
                                </el-form-item>
                            </el-col>

                            <el-col :span="14">
                                <el-form-item label="优惠卷类型" prop="useType">
                                    <el-radio-group v-model="form.useType">
                                        <el-radio v-for="dict in useDict" :key="dict.value" :label="dict.value">{{
                                            dict.label }}
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>

                            <el-col :span="14" v-if="form.useType == 1">
                                <el-form-item label="关联分类" prop="categoryIds">
                                    <el-select v-model="form.categoryIds" multiple placeholder="请选择">
                                        <el-option v-for="item in categoryList" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="14" v-if="form.useType == 2">
                                <el-form-item label="关联产品" prop="spuIds">
                                    <el-select v-model="form.spuIds" multiple placeholder="请选择">
                                        <el-option v-for="item in spuCouponList" :key="item.id" :label="item.spuName"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <!-- <el-col :span="14">
                                <el-form-item label="优惠劵图片" prop="couponImg">
                                    <imageUpload v-model="form.couponImg" :isShowTip="false" :limit="parseInt(1)" />
                                    <span class="el-upload__tip">请上传比例为： <b style="color: #f56c6c">4:3</b> 的图片规格</span>

                                </el-form-item>
                            </el-col> -->
                            <el-col :span="14">
                                <el-form-item label="发行数量" prop="publishCount">
                                    <el-input-number v-model="form.publishCount" :min="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="用户限领张数" prop="perLimit">
                                    <el-input-number v-model="form.perLimit" :min="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="首页领劵" prop="isSlide">
                                    <el-radio-group v-model="form.isSlide">
                                        <el-radio v-for="dict in isSlideDict" :key="dict.value" :label="dict.value">{{
                                            dict.label }}
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="减扣类型" prop="reduceType">
                                    <el-radio-group v-model="form.reduceType">
                                        <el-radio v-for="dict in reduceDict" :key="dict.value" :label="dict.value">{{
                                            dict.label }}
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="折扣力度" prop="discount" v-if="form.reduceType == '2'">
                                    <el-input-number v-model="form.discount" :precision="2" :step="0.01" :min="0"
                                        :max="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="抵扣金额" prop="amount" v-if="form.reduceType == '1'">
                                    <el-input-number v-model="form.amount" :precision="2" :step="1"
                                        :min="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="有效时间" prop="validDate">
                                    <el-date-picker v-model="form.validDate" @change="selectDate"
                                        value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
                                        type="datetimerange" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="可领取时间段" prop="edibleDate">
                                    <el-date-picker v-model="form.edibleDate" @change="selectDate2"
                                        value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
                                        type="datetimerange" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="备注" prop="note">
                            <el-input v-model="form.note" type="textarea" :rows="2" placeholder="请输入备注" />
                        </el-form-item>
                        <el-form-item label="启用状态" prop="startStatus" >
                            <el-radio-group v-model="form.startStatus">
                                <el-radio v-for="dict in startDict" :key="dict.value" :label="dict.value">{{
                                    dict.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'Extract',
    data() {
        return {
            // 列表
            list: [],
            itemList: [],
            // 类型
            couponTypeDict: {
                '0': { color: 'success', label: '全场赠卷' },
                '1': { color: 'success', label: '会员赠卷' },
                '2': { color: 'success', label: '购物赠卷' },
                '3': { color: 'success', label: '注册赠卷' },
            },
            // 优惠卷分类
            couponDict: [
                { value: 0, label: '全场赠券' },
                { value: 1, label: '会员赠卷' },
                { value: 2, label: '购物赠券' },
                { value: 3, label: '注册赠券' },
            ],
            // 扣减类型
            reduceDict: [
                { value: 1, label: '金额' },
                { value: 2, label: '折扣' }
            ],
            // 使用类型
            useDict: [
                { value: 0, label: '全场通用' },
                { value: 1, label: '指定分类' },
                { value: 2, label: '指定商品' },
            ],
            // 启用状态
            startDict: [
                { value: 0, label: '禁用' },
                { value: 1, label: '启用' },
            ],
            // 首页轮播图
            // 首页领取
            isSlideDict: [
                { value: 0, label: '不参与' },
                { value: 1, label: '参与' }
            ],
            // 总条数
            total: 0,
            // 遮罩层
            loading: false,
            // 查询
            query: {
                current: 1,
                size: 10,
                couponName: '',
                couponType: null,
                reduceType: null,
                useType: null,
                startStatus: null,
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 弹窗控制器
            open: false,
            // 弹窗标题
            title: '添加优惠卷',
            // 弹窗数据
            form: {
                // 选择的时间区间
                validDate: [],
                // 可领取的时间区间
                edibleDate: [],
            },
            // 表单校验
            rules: {
                couponType: [{ required: true, message: "请选择类型", trigger: "blur" }],
                couponName: [
                    { required: true, message: "请填写优惠券名称", trigger: "blur" },
                ],
                validDate: [{ type: 'array', required: true, message: "请选择有效时间", trigger: "change" }],
                edibleDate: [{ type: 'array', required: true, message: "请选择可领取时间", trigger: "change" }],

            },
            activeName: "basic",
            // 商品列表
            spuCouponList: [],
            // 分类列表
            categoryList: [],
            // 开启多选模式
            props: { multiple: true },
        }
    },
    created() {
        this.getList()
        this.getCatalogList()
        this.getSpuList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.couponList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query = {
                current: 1,
                size: 10,
                couponName: '',
                couponType: null,
                reduceType: null,
                useType: null,
                startStatus: null,
            }
            this.form = {
                id: null,
                couponType: 0,
                couponName: '',
                couponImg: '',
                publishCount: 1,
                reduceType: 1,
                useType: 0,
                isSlide: 0,
                discount:  0.00,
                amount: 1,
                perLimit: 1,
                startTime: null,
                endTime: null,
                enableStartTime: null,
                enableEndTime: null,
                note: '',
                publish: 0,
                startStatus: 1,
                // 关联分类
                categoryIds: [],
                // 关联产品
                spuIds: [],
            };
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加优惠券";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.form = row
            this.form.validDate = [row.startTime, row.endTime]
            this.form.edibleDate = [row.enableStartTime, row.enableEndTime]
            this.open = true
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除编号为"' + ids + '"的数据项？').then(function () {
                return api.couponRemove({ ids: ids + '' });
            }).then(() => {
                this.getList();
                this.$message.success("删除成功");
            }).catch(() => { });
        },
        /** 选择有效日期 */
        selectDate(e) {
            console.log(e);
            this.form.startTime = e[0]
            this.form.endTime = e[1]
        },
        /** 选择可领取有效日期 */
        selectDate2(e) {
            console.log(e);
            this.form.enableStartTime = e[0]
            this.form.enableEndTime = e[1]
        },
        /** 确定 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    console.log(this.form);
                    if (this.form.id != null) {
                        // 修改
                        api.updateCoupon(this.form).then(res => {
                            this.$message.success("修改成功")
                            this.reset()
                            this.open = false
                        })
                    }else {
                        // 添加
                        api.createCoupon(this.form).then(res => {
                            this.$message.success("添加成功")
                            this.reset()
                            this.open = false
                        })
                    }
                }
            })
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 获取分类
        getCatalogList() {
            api.getCatalogList().then(res => {
                this.categoryList = res
            })
        },
        // 获取商品
        getSpuList() {
            api.getSpuList().then(res => {
                this.spuCouponList = res
            })
        }
    }
}

</script>